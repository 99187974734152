#api-page {
  box-sizing: border-box;
  display: grid;
  gap: var(--spacing-02);
  grid-template-columns: 100%;
  /* grid-template-rows: auto auto max(36vh, 20rem) 80%; */
  grid-template-rows: auto auto min-content min-content 80%;
  height: 100%;
  overflow: auto;
  padding-bottom: var(--spacing-02);
  padding-left: var(--spacing-02);
  padding-right: var(--spacing-02);
  padding-top: var(--spacing-02);
}

.orgId-authkey-container {
  display: grid;
  gap: var(--spacing-02);
}

.card-copy-section {
  grid-template-columns: 1fr 2fr;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .orgId-authkey-container {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
}
