.cookies-modal {
  position: fixed;
  width: 60vw;
  background-color: #333333;
  color: #fff;
  padding: 24px;
  height: fit-content;
  left: 20vw;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 20;
}

.slideInCookiesClass {
  animation: slideInCookies 0.3s forwards;
}

.slideOutCookiesClass {
  animation: slideOutCookies 0.3s forwards;
}

.cookies-modal-text {
  width: 100%;
  height: fit-content;
  color: #fff;
  margin-bottom: 24px;
}

.cookies-modal-text a {
  text-decoration: underline;
}

.cookies-modal .buttons {
  width: 100%;
  display: flex;
}

.cookies-modal .buttons button {
  width: calc(50% - 8px) !important;
}

.cookies-modal .buttons button:first-child {
  margin-right: 16px;
}

@keyframes slideInCookies {
  from {
    bottom: -50%;
  }

  to {
    bottom: 24px
  }
}

@keyframes slideOutCookies {
  from {
    bottom: 24px
  }

  to {
    bottom: -50%;
  }
}

@media screen and (max-width: 900px) {
  .cookies-modal {
    position: fixed;
    width: calc(100% - 16px - 48px);
    background-color: #333333;
    color: #fff;
    padding: 24px;
    height: fit-content;
    bottom: 24px;
    left: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    z-index: 20;
  }
}

