#settings-page {
  display: grid;
  grid-template-columns: 1fr;
  height: calc(100vh - var(--spacing-12));
  inset: 0;
  position: absolute;
  grid-template-areas: "cards" "main";
  grid-template-rows: min-content 1fr;
}

@media screen and (min-width: 1280px) {
  #settings-page {
    grid-template-areas: "main cards";
    grid-template-rows: 100%;
  }
}

.settings-page-content {
  display: grid;
  grid-gap: var(--spacing-02);
  box-sizing: border-box;
  padding: var(--spacing-02);
  padding-left: var(--spacing-03);
}

#settings-page .cards {
  align-content: start;
  background: var(--colors-neutral-gray20);
  background: var(--colors-neutral-gray20);
  box-shadow: 0 0 0 calc(var(--border-widths-normal))
      var(--colors-semi-transparent-light10),
    0 0 0 var(--border-widths-large) var(--colors-semi-transparent-dark5);
  display: grid;
  gap: var(--spacing-02);
  grid-area: cards;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  padding: var(--spacing-02);
  padding-left: var(--spacing-03);
  position: relative;
  height: fit-content;
}

@media screen and (min-width: 1280px) {
  #settings-page .cards {
    box-shadow: calc(-1 * var(--border-widths-normal)) 0 0 0
        var(--colors-semi-transparent-light10),
      0 0 0 var(--border-widths-large) var(--colors-semi-transparent-dark5);
    grid-auto-flow: row;
    padding-left: var(--spacing-02);
  }
}

#settings-page .tabs {
  overflow: hidden;
  height: 100%;
}

#settings-page .tabs > div:nth-child(2) {
  height: 100%;
  overflow-y: hidden;
}

#settings-page .role-tabs > :nth-child(2) {
  box-sizing: border-box;
  padding: 8px;
}

.role-tabs {
  grid-template-rows: min-content max-content !important;
  height: fit-content;
}

.tab-content {
  box-sizing: border-box;
  grid-gap: var(--spacing-02);
  height: 100%;
  padding-top: var(--spacing-02);
  height: 100%;
}

.tab-content > *:not(.buttons-lower) {
  margin-left: var(--spacing-03);
  margin-right: var(--spacing-02);
  width: calc(100% - var(--spacing-03) - var(--spacing-02));
  box-sizing: border-box;
}

.org-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 8.25rem 18rem 1fr auto;
  gap: 8px;
}

@media screen and (min-width: 1280px) {
  .org-content {
    grid-template-rows: auto 8.25rem 1fr auto;
  }
}

.org-table {
  max-height: 40vh;
}

@media screen and (min-width: 1280px) {
  .org-table {
    max-height: 100%;
    min-height: 16rem;
  }
}

.action-card > div {
  margin-bottom: var(--spacing-04);
}

.sub-org-audit-log-container {
  width: 100%;
  display: grid;
  grid-gap: var(--spacing-02);
}

@media screen and (min-width: 1280px) {
  .sub-org-audit-log-container {
    grid-template-columns: 1fr 2fr;
  }

  .no-sub-orgs-container {
    grid-template-columns: 1fr;
  }
}

.entry-wrapper {
  cursor: pointer;
  padding-bottom: var(--spacing-01half);
  padding-left: var(--spacing-04);
  padding-right: var(--spacing-04);
  padding-top: var(--spacing-01half);
}

.entry-wrapper:hover,
.entry-wrapper.active {
  background-color: var(--table-accent-color-light);
  box-shadow: inset calc(var(--border-widths-small)) 0 0 0
      var(--table-accent-color-dark),
    inset calc(-1 * var(--border-widths-normal)) 0 0 0
      var(--table-accent-color-dark),
    inset 0 calc(-1 * var(--border-widths-small)) 0 0
      var(--table-accent-color-dark),
    0 calc(-1 * var(--border-widths-small)) 0 0 var(--table-accent-color-dark);
}

.entry {
  display: flex;
  gap: var(--spacing-02);
}

.entry .toggle-container {
  margin: var(--spacing-01) 0;
}

.audit-log-right {
  flex: 1;
}

.audit-log-right p {
  margin: var(--spacing-01) 0 !important;
}

.audit-log-right .white-text {
  color: var(--colors-neutral-light) !important;
}

.audit-log-right .blue-text {
  color: var(--colors-brand-bright-blue) !important;
  font-size: var(--font-sizes-small) !important;
}

.audit-log-right .orange-text {
  color: var(--colors-brand-bright-orange) !important;
}

.audit-log-right .gray-text {
  color: var(--colors-neutral-gray70) !important;
  font-size: var(--font-sizes-small) !important;
}

.avatar {
  height: 29px;
  width: 29px;
  border: 1px solid #9b9b9b;
  border-radius: 29px;
}

.full-log {
  display: block;
  margin-bottom: var(--spacing-02);
  margin-top: var(--spacing-03);
}

.full-log > div {
  background-color: var(--colors-semi-transparent-light5) !important;
  border-radius: var(--radii-rounded);
}

.role-button {
  border-radius: var(--radii-full);
  margin: 0 var(--spacing-01) var(--spacing-01) var(--spacing-01);
  width: calc(100% - var(--spacing-02)) !important;
}

.buttons-lower {
  background-color: var(--colors-semi-transparent-light5);
  backdrop-filter: blur(var(--radii-large));
  box-shadow: inset 0 var(--border-widths-normal) 0 0
    var(--colors-semi-transparent-light5);
  display: grid;
  grid-gap: var(--spacing-02);
  padding-bottom: var(--spacing-02half);
  padding-left: var(--spacing-03);
  padding-top: var(--spacing-02);
  padding-right: var(--spacing-02);
  position: sticky;
  bottom: 0;
  z-index: 2;
}

@keyframes buttonsIn {
  from {
    opacity: 0;
    bottom: -10em;
    height: 0;
    position: absolute;
  }

  to {
    opacity: 1;
    bottom: 0;
    height: 8em;
    position: sticky;
  }
}

@keyframes buttonsOut {
  from {
    opacity: 1;
    bottom: 0;
    height: 8em;
    position: sticky;
  }

  to {
    opacity: 0;
    bottom: -10em;
    height: 0;
    position: absolute;
  }
}

.buttons-lower-in {
  animation: buttonsIn calc(1s * var(--durations-long)) ease-in-out forwards;
}

.buttons-lower-out {
  animation: buttonsOut calc(1s * var(--durations-long)) ease-in-out forwards;
}

.profile-tab-content {
  padding-bottom: var(--spacing-02);
  grid-template-columns: 1fr;
  display: grid;
  overflow-y: auto;
}

div[name="Profile"],
.profile-container {
  height: 100%;
  display: grid;
}

.profile-container p:not(:last-child) {
  margin-bottom: var(--spacing-02);
}

.buttons-lower button {
  margin: 0;
}

.rotate-0 {
  transition: var(--durations-medium);
  transform: rotate(0deg);
}

.rotate-90-c {
  transition: var(--durations-medium);
  transform: rotate(90deg);
}

.cookies-toggle-card {
  /* height: 2.75em; */
  background-color: #00cc33;
  padding: 14px 16px;
}

.cookies-card a {
  text-decoration: underline;
}

.cookies-toogle-card span {
  background-color: #51FFC8 !important;
}

._2V9Ge:checked + ._p5wgA {
  background-color: #51FFC8 !important;
}

._p5wgA:before {
  background-color: black !important;
}

.cookies-toggle-card p {
  color: black;
  margin: 0;
  font-size: 10px;
}

@media screen and (max-width: 1000px) {
  #settings-page {
    grid-template-columns: 1fr;
  }
}

.roles-table-entry td{
  word-wrap: break-word !important;
  white-space: normal !important;
  overflow-wrap: break-word !important;
  overflow: auto;
}

.user-button{
  display: flex;
  align-items: center;
}

.user{
  margin: 0 
}

.description {
  padding-right:10px;
}


.single-line {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.toggle-right-align {
  margin-left: auto;
  margin-right: 20px;
}