.sub-org-view {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.sub-org-header-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-template-rows: min-content;
  align-items: center;
  box-sizing: border-box;
  padding: var(--spacing-06);
  gap: var(--spacing-06);
}

.sub-org-billing-button {
  width: fit-content;
  margin-bottom: 0px;
}

.sub-org-header-half {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 4px;
}

.sub-org-header-name {
  width: 100%;
  font-size: var(--font-sizes-large);
  font-family: var(--font-families-heading);
}

.sub-org-header-name i {
  height: var(--font-sizes-large);
  width: var(--font-sizes-large);
  cursor: pointer;
  color: var(--colors-neutral-gray40);
  padding-left: 4px;
  margin-bottom: -4px;
}

.sub-org-header-name i:hover {
  color: var(--colors-neutral-gray98);
}

.sub-org-header-id {
  width: 100%;
  font-size: var(--font-sizes-normal);
  color: var(--colors-neutral-gray70);
}

.sub-org-header-parent-label {
  width: 100%;
  font-size: var(--font-sizes-normal);
  text-align: end;
  font-family: var(--font-families-heading);
}

.sub-org-header-parent {
  text-align: end;
  width: 100%;
  font-size: var(--font-sizes-small);
  color: var(--colors-neutral-gray70);
}

.model-limit {
  width: 100%;
  padding-left: var(--spacing-06);
  box-sizing: border-box;
}

.sub-org-row {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: var(--spacing-02) var(--spacing-06);
}

.disabled-row {
  display: none !important;
}

.access-save-info {
  font-size: 12px;
  line-height: 18px;
  font-family: var(--font-families-normal);
  color: var(--colors-neutral-gray70);
}

/* .disabled-row {
  position: relative;
  display: none;
}

.disabled-section, .disabled-overlay {
  position: absolute;
  top: 0;
  width: 100%;
}

.disabled-overlay {
  top: 36px;
  border-radius: var(--radii-rounded);
  z-index: 99;
  width:  calc(100% - 48px);
  height: calc(100% - 44px);
  cursor: not-allowed;
  background:black;
  background:rgba(100, 100, 100, 0.6);
} */

.disabled-graph-select > * > *:not(.disabled-notification) {
  opacity: .2;
  cursor: not-allowed;
}

.disabled-notification {
  opacity: 1 !important;
  color: white !important;
}

.sub-org-row-title {
  width: 100%;
  height: fit-content;
  font-size: var(--font-sizes-large);
  font-family: var(--font-families-heading);
}

.sub-org-row-body {
  width: 100%;
  height: fit-content;
  padding-top: var(--spacing-04);
}

.sub-org-row-body-margin-top {
  padding-top: 0px !important;
  margin-top: var(--spacing-04);
}

.sub-org-row-spacing {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-03);
}

label[for$="-switch"] {
  padding: 1px;
  margin-bottom: 0px;
}

.sub-org-bottom-padding {
  padding: var(--spacing-08);
}

.org-table {
  max-height: 40vh;
}

.unsaved-text {
  font-size: var(--font-sizes-normal);
  color: var(--colors-brand-state-warn-contrast-dark);
}

.reset-text {
  font-size: var(--font-sizes-normal);
  color: var(--colors-brand-state-error-contrast-dark);
  cursor: pointer;
}

.view-only {
  color: var(--colors-neutral-gray70);
  font-size: var(--font-sizes-large);
  font-family: var(--font-families-heading);
}

.sub-org-view table tr {
  margin-left: 1px;
  width: calc(100% - 1px);
  box-sizing: border-box;
}

.sub-org-split-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 8px;
}

.reset-auth-key-body, .reset-auth-key-body span {
  color: var(--colors-brand-state-error-contrast-dark) !important;
  overflow-x: initial !important;
}

.reset-auth-key-body input:checked+span {
  background-color: var(--colors-brand-state-error-contrast-dark) !important;
}

.reset-auth-key-body svg {
  color: black !important;
}

.input-error div {
  border-color: var(--colors-brand-state-error-contrast-dark) !important;
}

.sub-org-vkg-perms {
  width: 100%;
}

.sub-org-vkg-perms > * {
  padding-left: 0px
}

.sub-org-vkg-perms .permission-collapse-body-row {
  padding-right: 0px;
}

.sub-org-vkg-perms * {
  font-family: var(--font-families-normal) !important;
}

.sub-org-user-table table tr {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, calc((100% - 16px - var(--spacing-06)) / 3)) 16px;
  box-sizing: border-box;
}

.sub-org-user-table table tr i:hover {
  cursor: pointer;
  color: var(--colors-brand-state-error-contrast-dark);
}
