.custom-modal-init {
  background: rgb(33, 33, 33);
  width: 40em;
  height: calc(100vh - 4em);
  min-height: 30em;
  max-height: 85vh;
  transition: 0.5s;
  position: fixed;
  z-index: 99;
}

.custom-modal-init > p {
  font-size: 1.3125em;
  font-weight: bold;
  margin: 40px 16px;
}

.modal-content {
  margin: 40px 16px;
}

.modal-content p {
  font-size: 1.3125em;
  font-weight: bold;
  margin: 8px 0;
}

.custom-modal-init footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-btn {
  height: 3em;
  width: 100%;
  color: #ffffff;
  background-color: #4a4a4a;
  border: 0;
  font-family: "Anonymous Pro";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  margin-bottom: 0;
}

.custom-modal-init header,
.custom-modal-init nav {
  height: 4em;
}

.custom-modal-init header {
  background: var(--colors-brand-green);
  display: flex;
  align-items: center;
}

.custom-modal-init header img {
  height: 100%;
}

.disabled-button {
  color: gray !important;
}

.notification-group {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20%;
  width: 100%;
}

.custom-modal-init header span {
  margin-left: 1.5em;
  font-size: 1.8125em;
}

.custom-modal-init form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 6em);
}

.sign-in-form {
  /* padding: 5em 0 !important; */
  box-sizing: border-box;
}

.custom-input {
  margin-bottom: 3em;
}

.custom-modal-init form > div {
  margin: 24px 24px 0;
  flex: 1;
  min-width: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#sign-up-inputs {
  margin-bottom: var(--spacing-08);
}

#sign-up-inputs > input:last-child {
  margin-bottom: 1.5em !important;
}

/* #sign-up-inputs > * {
   overflow: hidden;
  } */

.custom-modal-init nav {
  display: flex;
}

.custom-modal-init nav a {
  text-decoration: none;
  height: 100%;
  flex: 1;
  width: 100%;
  cursor: pointer;
}

.custom-modal-init nav a button {
  width: 100%;
}

.custom-modal-init nav button {
  flex: 1;
  cursor: pointer;
  height: 100%;
  border: none;
  border-bottom: 1px solid rgb(65, 65, 65);
  outline: none;
  background: rgb(65, 65, 65);
  color: white;
}

.underlined-tab {
  border-bottom: 1px solid rgb(135, 240, 254) !important;
}

.requirement-item {
  display: flex;
  align-items: center;
  height: 3em;
}

.requirements h3 {
  color: #f2f1e9;
}

.requirements p {
  color: #9b9b9b;
  font-family: "Anonymous Pro", monospace !important;
  font-size: 1.2em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.highlight {
  color: var(--colors-brand-bright-green) !important;
}

.success-link {
  width: 100%;
}

.disabled-button {
  cursor: default !important;
}

.notification-group {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20%;
  width: 100%;
}

.icon-notify-error {
  width: 40%;
  /* background-color: var(--colors-brand-state-error-contrast-dark); */
  filter: invert(28%) sepia(77%) saturate(5587%) hue-rotate(10deg)
    brightness(113%) contrast(101%);
  justify-content: center;
  display: flex;
}

.highlight {
  color: var(--colors-brand-bright-green) !important;
}

.icon-notify-success {
  width: 40%;
  /* background-color: var(--colors-brand-bright-green); */
  filter: invert(100%) sepia(13%) saturate(7211%) hue-rotate(67deg)
    brightness(97%) contrast(107%);
  justify-content: center;
  display: flex;
}

.disabled-button {
  cursor: default !important;
}

.notification-group {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20%;
  width: 100%;
}

.icon-notify-error {
  width: 40%;
  /* background-color: var(--colors-brand-state-error-contrast-dark); */
  filter: invert(28%) sepia(77%) saturate(5587%) hue-rotate(10deg)
    brightness(113%) contrast(101%);
  justify-content: center;
  display: flex;
}

.text-notify-error {
  justify-content: center;
  display: flex;
  color: var(--colors-brand-state-error-contrast-dark);
  margin-top: 5%;
  width: 80%;
  text-align: center;
}

.icon-notify-success {
  width: 40%;
  /* background-color: var(--colors-brand-bright-green); */
  filter: invert(100%) sepia(13%) saturate(7211%) hue-rotate(67deg)
    brightness(97%) contrast(107%);
  justify-content: center;
  display: flex;
}

.text-notify-success {
  justify-content: center;
  display: flex;
  color: var(--colors-neutral-gray95);
  margin-top: 5%;
  width: 80%;
  text-align: center;
}
