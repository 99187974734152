.limits {
  background-color: var(--colors-neutral-dark);
  box-shadow: var(--shadows-blur-dark-small), inset 0 0 0 var(--border-widths-normal) var(--colors-semi-transparent-light10);
  color: var(--colors-neutral-light);
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 8px;
}

.limit-inputs {
  width: 100%;
  box-sizing: border-box;
  display: block !important;
}

.limit-inputs > h3 {
  font-size: 14px;
}

.limit-inputs-existing > *:not(.limit-action-row), .limit-inputs-existing > *:not(.limit-action-row) * {
  cursor: not-allowed !important;
  pointer-events: none;
}

.limit-inputs-existing > *:not(.limit-action-row) #options-container {
  display: none;
}

.limit-inputs > *:not(:last-child), .limit-inputs:not(:last-child) {
  margin-bottom: 16px;
}

.limit-inputs input {
  color-scheme: dark;
}

.limit-header {
  width: 100%;
  height: fit-content;
  font-size: var(--font-sizes-large);
  font-family: var(--font-families-heading);
}

.limit-action-row {
  width: 100%;
  display: flex;
  align-items: center;
}

.limit-action-row > * {
  margin: 0px;
}

.limit-split-action-row {
  display: grid;
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);
  gap: 16px;
  grid-template-rows: 1fr;
}

.input-header {
  margin-bottom: 0 !important;
  font-size: var(--font-sizes-small);
  color: var(--colors-brand-bright-green);
  font-family: var(--font-families-stylized);
}

.disabled-hard-limits, .disabled-hard-limits * {
  cursor: not-allowed;
}

.disabled-hard-limits label{
  padding: 2px;
}

.update-alt-text {
  font-size: 12px;
  line-height: 18px;
  font-family: var(--font-families-normal);
}