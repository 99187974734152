#dashboard-div {
  display: grid;
  grid-auto-columns: 1fr;
  /* grid-template-areas: "l l l l l l l l l r r r"; */
  grid-template-areas: "l l l l l l l l l l l l";
  grid-template-rows: 100%;
  height: 100%;
  left: 0;
  overflow: auto;
  right: 0;
  position: absolute;
}

.dashboard-left {
  grid-area: l;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  box-sizing: border-box;
  padding: var(--spacing-02) var(--spacing-02) var(--spacing-02)
    var(--spacing-03);
}

.dashboard-left-old {
  grid-area: l;
  display: grid;
  grid-template-columns: repeat(18, minmax(0, 1fr));
  /* grid-template-rows: calc(42% - var(--spacing-02)) 16% calc(
      42% - var(--spacing-02)
    ); */
  grid-template-rows: min-content calc(
    42% - var(--spacing-02)
  );
  grid-template-areas:
    /* "tl tl tl tl tl tl tl tl tl tr tr tr tr tr tr tr tr tr"
    "mdc1 mdc1 mdc1 mdc1 mdc1 mdc1 mdc2 mdc2 mdc2 mdc2 mdc2 mdc2 mdc3 mdc3 mdc3 mdc3 mdc3 mdc3" */
    "x x x x x x x x x x x x x x x x x x"
    "l l l l l l l l l l l l l l l l l l";
  grid-gap: var(--spacing-02);
  min-height: 768px;
  padding: var(--spacing-02) var(--spacing-02) var(--spacing-02)
    var(--spacing-03);
  overflow: auto;
}

.requests-graph {
  height: 100%;
  display: flex;
  gap: var(--spacing-04);
  grid-template-columns: 100%;
  grid-template-rows: auto calc(100% - var(--spacing-06));
  flex-direction: column;
  grid-area: tr;
}

.requests-graph-content {
  flex: 1;
  gap: var(--spacing-04);
  height: 100%;
}

#dashboard-div .api-usage {
  grid-area: tl;
}

#dashboard-div .under-construction-card {
  grid-area: x;
}

.under-construction-card h3 {
  margin-bottom: 0;
  color: var(--colors-brand-gold);
  font-size: 16px;
}

.under-construction-card * {
  gap: 0 !important;
}

#dashboard-div .org-tables {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 100%;
  gap: 8px;
  grid-template-rows: calc(50% - 4px) calc(50% - 4px);
  height: 100%;
}

#dashboard-div .org-tables-old {
  grid-area: l;
  display: flex;
  row-gap: var(--spacing-02);
  flex-direction: column;
}

#dashboard-div .logs {
  height: 100%;
}

#dashboard-div .models {
  height: 100%;
}

.dashboard-right {
  grid-area: r;
  position: sticky;
  top: 0;
}

.dashboard-right > div {
  background: var(--colors-neutral-gray30);
  border-radius: 0 0 0 var(--radii-large);
  box-shadow: var(--shadows-blur-dark-medium-light);
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: var(--spacing-02);
  grid-template-areas:
    "r1"
    "r2";
  padding: var(--spacing-02);
}

.chart-stat-container:first-child {
  margin-right: var(--spacing-04);
}

.donut-chart-container {
  width: 50%;
  /* height: 184px; */
  height: 100%;
  display: flex;
  align-items: center;
}

.donut-chart-data-container {
  align-content: center;
  align-items: center;
  display: grid;
  flex: 1;
  font-size: var(--font-sizes-normal);
  justify-content: center;
  width: 50%;
}

.donut-chart-data-container .total-container {
  margin-bottom: var(--spacing-04);
}

/* .dashboard-right .metric-card {
  margin-bottom: 8px;
} */

.donut-chart-data-container .text-bright-green {
  font-size: 1.75em;
}

.mdc-1 {
  grid-area: mdc1;
}

.mdc-2 {
  grid-area: mdc2;
}

.mdc-3 {
  grid-area: mdc3;
}

/* @media screen and (max-width: 1280px) {
  #dashboard-div {
    grid-template-rows: auto;
    grid-template-areas:
      "r"
      "l";
  }

  .dashboard-left {
    grid-template-rows: max(24rem, 60%) 1fr 100%;
    min-height: 0;
  }

  .dashboard-right > div {
    grid-template-areas: "r1 r2";
    grid-auto-columns: 1fr;
    border-radius: 0;
    padding-left: var(--spacing-03);
  }
}

@media screen and (max-width: 1024px) {
  .dashboard-left {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max(20rem, 36vh) max(20rem, 36vh) auto auto 80%;
    grid-template-areas:
      "tl tl"
      "tr tr"
      "mdc1 mdc1"
      "mdc2 mdc3"
      "l l";
  }

  .donut-chart-container {
    width: 50%;
    gap: var(--spacing-08);
  }

  .donut-chart-container div {
    aspect-ratio: auto !important;
  }

  .donut-chart-data-container {
    justify-content: flex-start;
  }
} */
