.single-line-padding {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.single-line-padding label, .single-line-padding button {
  margin-bottom: 0;
}

.manage-role-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.padding {
  padding: 20px;
}

.bright-green {
  color: var(--colors-brand-bright-green);
}

.single-line {
  display: inline-flex;
  align-items: center;
  overflow: visible;
  z-index: 1;
  width: 95%;
}

#roles-page {
  grid-template-columns: 1fr;
  height: 100%;
}

.role-input-wrapper * {
  font-family: var(--font-families-normal) !important;
}

/* .roles-table-entry td{
  word-wrap: break-word !important;
  white-space: normal !important;
  overflow-wrap: break-word !important;
  overflow: auto;
} */

.roles-table {
  margin: 12px;
  width: calc(100% - 24px) !important;
}

.hover-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.toggle-right-align {
  margin-left: auto;
}

.prevent-overflow {
  width: 98%;
}

.dark-gray {
  color: var(--colors-neutral-gray60);
}

.light-blue {
  color: var(--colors-brand-bright-blue);
}

.role-title-header {
  padding: 18px 12px 0px 18px;
  box-sizing: border-box;
}

#role-tab .user-button {
  display: flex;
  align-items: center;
}

#role-tab .user-button button {
  margin: 0;
}

#role-tab .description {
  margin: 0;
}

.table-header-div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.table-header-div i{
  height: 16px;
  width: 16px;
}

.perm-switch-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  /* max-width: 40em; */
  padding-left: 24px;
  box-sizing: border-box;
  font-family: var(--font-families-normal) !important;
  font-size: 14px;
}

.perm-switch-text {
  font-family: var(--font-families-normal) !important;
  font-size: 14px;
  font-weight: 500;
}

.perm-switch-row label {
  padding: 2px;
}

.perm-title {
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--colors-neutral-gray30);
  /* max-width: 35em; */
}

.perm-switch-row p {
  font-size: 12px;
  /* max-width: 30em; */
  font-family: var(--font-families-normal);
  font-weight: 400;
}

.role-tabs {
  margin-bottom: var(--spacing-04);
}

.roles-table > div > span {
  width: 100%;
}

.role-input-delete-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  grid-template-rows: 1fr;
  align-items: flex-end;
  padding-bottom: 20px;
}

.role-input-delete-row > * {
  margin: 0px !important;
}

#general-tab h3 {
  font-family: var(--font-families-normal) !important;
}

.custom-role-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-role-header > * {
  margin: 0px !important;
}

.role-header-text {
  width: fit-content;
  margin: 0px;
  padding: 0px;
}

.role-wrapper {
  height: 100%;
}

.disabled-switch, .disabled-switch * {
  cursor: not-allowed !important;
}

.back-roles {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  line-height: 16px;
}

.back-roles-text {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: -4px;
}

.back-roles i {
  height: 16px;
  width: 16px;
}