.table {
  width: 100%;
  display: block;
}

.table .header {
  width: 100%;
  margin-bottom: 19px;
}

.table-body-container {
  overflow: scroll;
}

.count {
  background: #292929;
  border-radius: 12.5px;
  padding: 5px 20px;
}

.count span {
  color: #9b9b9b;
}

.tr-container {
  padding-bottom: 8px;
  border-bottom: 1px solid #3c3c3c;
  margin-bottom: 18px;
}

.table-content {
  width: 100%;
  overflow: auto;
}

.grid-flex-evenly {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  /* grid-template-rows: 1fr; */
}

.flex-1 > * {
  flex: 1;
}

.logs-flex > *:first-child {
  flex: 150px;
}

.table-entry {
  font-size: 21px;
  height: 3.375em;
  align-items: center;
  /* font-size: 1.3125em; */
  border: 1px solid #1b1b1b;
  box-sizing: border-box;
}

.table-entry > div {
  overflow: auto;
  margin-right: 10px;
  box-sizing: border-box;
}

.table-entry > div:first-child {
  padding-left: 20px;
  box-sizing: border-box;
}

#user-table-entry > div:last-child {
  overflow: visible;
}

.table-entry:hover {
  border-color: white;
  background: black;
}

div[data-status="SUCCESS"] {
  color: #b8e986 !important;
}

div[data-status="FAILURE"] {
  color: #d0021b !important;
}

div[data-status="Success"] {
  color: #b8e986 !important;
}

div[data-status="Failure"] {
  color: #d0021b !important;
}
