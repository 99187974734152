.sub-org-dashboard {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: calc(100% - 1em - 40px) 40px;
  gap: 1em;
}

.sub-org-dashboard .sub-org-footer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.sub-org-dashboard .sub-org-body {
  padding: 1em 1em 0em 1em;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.sub-org-dashboard .sub-org-table {
  height: 100%;
}

.sub-org-dashboard .sub-org-table #sub-orgs-table {
  grid-template-rows: auto 1fr;
}

.sub-org-dashboard .sub-org-table #sub-orgs-table tbody {
  grid-auto-rows: min-content;
}

.sub-org-dashboard .sub-org-table #sub-orgs-table tbody * {
  height: fit-content;
  width: calc(100% - 1px);
  margin-left: 1px;
  box-sizing: border-box;
}

.sub-org-dashboard a:hover {
  text-decoration: underline;
}

label[for$="-switch"] {
  padding: 1px;
}

.new-sub-org-modal-content-wrapper {
  height: 100%;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: min-content;
  gap: 16px;
  padding-right: 4px;
}