.limit-usage-card > h3 {
  font-size: 16px;
  line-height: 24px;
  color: var(--colors-neutral-gray95);
}

.last-update-notification {
  font-family: var(--font-families-normal);
  color: var(--colors-neutral-gray70);
}

.no-active-limits {
  font-family: var(--font-families-normal);
  font-size: 14px;
  line-height: 21px;
}

.limits-loading {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.limits-loading svg {
  position: relative;
  height: 48px;
  width: 48px;
}