pre {
  margin: 0;
}

.code {
  background-color: var(--colors-neutral-gray15);
  box-sizing: border-box;
  border-radius: var(--radii-small);
  white-space: pre-line;
}

.curl {
  text-wrap: wrap !important;
  overflow-wrap: break-word !important;
}

.code-snippet code {
  text-overflow: unset !important;
}
  
code.hljs {
  box-sizing: border-box;
  color: var(--colors-brand-light-blue);
  display: block;
  overflow: scroll !important;
  width: 100%;
  white-space: pre-line;
}

.hljs-name,
.hljs-type,
.hljs-attribute,
.hljs-symbol,
.hljs-bullet,
.hljs-built_in,
.hljs-addition,
.hljs-variable,
.hljs-template-tag,
.hljs-template-variable,
.hljs-title {
  color: var(--colors-brand-bright-blue);
}

.hljs-string {
  color: var(--colors-brand-bright-blue);
}
