.time {
  color: #767676 !important;
}

.api-type {
  color: #767676 !important;
}

#scrollToTopBtn {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5px;
  background: none;
  border: none;
}

#scrollToTopBtn img {
  width: 50px;
  height: 50px;
}

.table-container {
  font-size: var(--font-sizes-normal);
  height: 100%;
  position: relative;
}

td[data-status="FAILURE"],
td[data-status="AUTH_FAILURE"] {
  color: var(--table-error-color) !important;
}
