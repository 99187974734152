.password-reset-container {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 99;
}

.password-reset-container > div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}