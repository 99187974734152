.modular-metrics-card {
  width: 100%;
  height: 400px;
  padding: 0px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--colors-semi-transparent-light10);
  display: grid;
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);
  grid-template-rows: 100%;
  gap: 16px;
  background-color: var(--colors-neutral-dark);
}

.modular-metrics-side {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 16px 0px;
  box-sizing: border-box;;
}

.modular-metrics-input {
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
}

.modular-metrics-input > * {
  margin-bottom: 0px !important;
  margin-top: 0;
}

.modular-metrics-input input {
  color-scheme: dark;
}

.modular-metrics-input > :nth-last-child(2) {
  margin-bottom: auto !important;
  margin-top: 0;
}

.modular-metrics-output {
  overflow-y: hidden;
  padding: 0px !important;
  position: relative;
}

.modular-metrics-output .loader-div {
  width: 100%;
  height: 100%;
  z-index: 3;
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  pointer-events: none;
}

.modular-metrics-output .output-list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 16px 0px 0px 0px;
  box-sizing: border-box;
}

.modular-metrics-output .output-card {
  width: 100%;
  height: fit-content;
  padding: 8px 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--colors-semi-transparent-light10);
  background-color: var(--colors-semi-transparent-light5);
  position: relative;
  margin-bottom: 16px;
}

.modular-metrics-output .output-card:hover {
  border: 1px solid var(--colors-semi-transparent-light20);
}

.modular-metrics-output .output-card .time-title {
  font-size: 14px;
  line-height: 21px;
  font-family: var(--font-families-stylized);
  width: 100%;
}

.modular-metrics-output .output-card .model-list {
  font-size: 12px;
  line-height: 18px;
  font-family: var(--font-families-normal);
  width: 100%;
  color: var(--colors-neutral-gray70)
}

.modular-metrics-output .output-card .action-section {
  display: none;
}

.modular-metrics-output .output-card:hover .action-section {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: transparent;
  display: grid;
  grid-template-columns: 24px;
  grid-template-rows: 24px;
  gap: 8px;
  z-index: 2;
  width: fit-content;
}

.modular-metrics-output .output-card .action-section .action-item {
  background-color: var(--colors-neutral-dark);
  border: 1px solid var(--colors-semi-transparent-light10);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modular-metrics-output .output-card .action-section .action-item:hover {
  border: 1px solid var(--colors-semi-transparent-light20);
}

.modular-metrics-output .output-card .action-section .action-item i {
  color: var(--colors-neutral-gray98);
  height: 16px;
  width: 16px;
  margin: 0px;
}

.modular-metrics-output .output-card .output-body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
}

.modular-metrics-output .output-card .output-body .body-kvp {
  width: 45%;
  min-width: 150px;
  padding: 4px;
  height: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px
}

.body-kvp * {
  font-family: var(--font-families-normal) !important;
  white-space: nowrap;
  overflow: hidden;
}

.body-kvp .body-key {
  font-size: 16px;
  line-height: 22px;
  color: var(--colors-neutral-gray98);
}

.full-kvp, .model-kvp {
  width: 100% !important;
}

.model-kvp .body-key {
  display: none;
}

.model-kvp .body-value {
  color: var(--colors-neutral-light) !important;
  font-family: var(--font-families-stylized) !important;
  width: 100% !important;
}

.body-kvp .body-value {
  font-size: 16px;
  line-height: 22px;
  color: var(--colors-neutral-gray90);
}

.output-card p {
  margin: 8px 0px 4px 0px;
  font-family: var(--font-families-normal) !important;
}