.full-loading-screen {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99 !important;
}

.time-loading {
  color: white !important;
  font-size: 16px;
}
