#quickstart-section {
  display: grid;
  grid-template-columns: 3fr 1fr;
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #quickstart-section {
    grid-template-columns: 100%;
    overflow-y: auto;
  }
}

.quickstart-left {
  box-sizing: border-box;
  display: grid;
  gap: var(--spacing-02);
  grid-template-columns: 100%;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding-bottom: var(--spacing-03);
  padding-left: var(--spacing-03);
  padding-right: var(--spacing-02);
  padding-top: var(--spacing-02);
}

@media screen and (max-width: 768px) {
  .quickstart-left {
    height: auto;
    overflow-y: visible;
  }
}
.quickstart-right {
  align-content: start;
  background-color: var(--colors-neutral-gray20);
  box-shadow: inset var(--border-widths-normal) 0 0 0
    var(--colors-semi-transparent-light10);
  display: grid;
  gap: var(--spacing-02);
  grid-template-rows: auto auto 1fr;
  padding-bottom: var(--spacing-02);
  padding-left: var(--spacing-02);
  padding-right: var(--spacing-02);
  padding-top: var(--spacing-02);
}

@media screen and (max-width: 768px) {
  .quickstart-right {
    border-top: solid var(--border-widths-normal) var(--colors-neutral-gray30);
    border-left: none;
    grid-auto-columns: 1fr;
    grid-template-rows: auto;
    padding-left: var(--spacing-03);
  }
}
#quickstart label {
  margin-bottom: 0;
}

.no-access-warning {
  display: grid;
  gap: var(--spacing-03);
  position: relative;
  padding: var(--spacing-06);
  text-align: left;
  color: var(--colors-brand-state-warn-contrast-dark);
}

.instruction-buttons {
  display: flex;
  margin-top: var(--spacing-05);
}

.quick-start-react-component {
  font-weight: normal !important;
}

.custom-card .copy-div {
  cursor: pointer;
}

.custom-card h4 {
  margin-bottom: 0 !important;
}

.quickstart-react-component {
  display: flex;
  height: 100%;
  font-weight: 300 !important;
}

.instruction-button {
  background: rgb(56, 56, 56);
  height: 2.375em;
  min-width: 140px;
  padding: 5px calc(25px / 2) 5px 25px;
  box-sizing: border-box;
  border-radius: 27px;
  display: flex;
  align-items: center;
}

.model-desc {
  min-height: 6rem;
  padding-bottom: 0;
}

.contactUs {
  align-self: end;
}
