.models-page {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 100%;
  gap: var(--spacing-03);
  padding: var(--spacing-03);
  box-sizing: border-box;
  overflow: auto;
}

.models-left {
  min-height: 0;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-rows: calc((100% - var(--spacing-03)) * .7) calc((100% - var(--spacing-03)) * .3); */
  grid-template-rows: 1fr auto; /* prevent code snippet overflow */
  gap: var(--spacing-03);
}

.models-left > div {
  min-height: 0;
}

@keyframes renderSelected {
  0% {display: none;}
  1% {display: block; width: 10px; opacity: 0.01;}
  100% {display: block; width: 400px; opacity: 1;}
}

@keyframes renderSelected2 {
  0% {display: none;}
  1% {display: block; max-height: 0px; opacity: 0.01;}
  100% {display: block; max-height: 300px; opacity: 1;}
}

.animate-model {
  animation-name: renderSelected;
  animation-duration: .2s;
}

.animate-snippet {
  animation-name: renderSelected2;
  animation-duration: .2s;
}

.selected-model {
  position: relative;
  width: 400px;
  height: 100%;
  background-color: var(--colors-neutral-dark);
  border-radius: 8px;
  box-shadow: var(--shadows-blur-dark-small),
    inset 0 0 0 var(--border-widths-normal)
      var(--colors-semi-transparent-light10);
  margin-bottom: 72px;
  overflow: auto;
}

.selected-model .main-title {
  font-size: 18px;
  color: #FFF000;
}

.selected-model .section {
  width: 100%;
  box-sizing: border-box;
  padding: var(--spacing-04);
  position: relative;
}

.section .copy-btn {
  display: none;
}

.selected-model .section:hover {
  background-color: var(--colors-semi-transparent-dark10);
}

.section:hover .copy-btn {
  display: block;
  height: var(--spacing-08);
  width: var(--spacing-08);
  box-sizing: border-box;
  padding: var(--spacing-01);
  background-color: var(--colors-semi-transparent-light30);
  border-radius: var(--spacing-01);
  position: absolute;
  top: var(--spacing-02);
  right: var(--spacing-02);
  cursor: pointer;
}

.section .copy-btn i {
  color: white;
  height: var(--spacing-06);
  width: var(--spacing-06);
}

.section .body {
  white-space: pre-wrap;
  word-break: break-word;
}

.selected-model .subtitle {
  font-size: 12px;
  font-family: var(--font-families-stylized);
  word-break: break-all;
}

.selected-model .body {
  font-size: 12px;
  font-family: var(--font-families-normal);
  /* word-break: break-all; */
}

.models-chart {
  padding: 1px;
  box-sizing: 1px;
  width: 100%;
  overflow: auto;
  position: relative;
  background-color: var(--colors-neutral-dark);
  border-radius: 8px;
  box-shadow: var(--shadows-blur-dark-small),
    inset 0 0 0 var(--border-widths-normal)
      var(--colors-semi-transparent-light10);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.models-chart-table-wrapper {
  display: block;
  position: relative;
  width: 100%;
  overflow: auto;
  height: 100%;
  border-radius: 0 0 8px 8px;
  /* border-radius: 8px; */
}

.models-chart-table-wrapper > div {
  width: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  box-sizing: border-box;
}

.models-chart-table-wrapper > div > span {
  width: 100%;
}

.model-chart-table-header {
  width: 100%;
  /* padding: var(--spacing-05); */
  padding: var(--spacing-04);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.model-chart-table-header .table-header {
  font-size: 18px;
}

.model-chart-table-header .compare-text {
  font-size: 12px;
  color: var(--colors-brand-bright-green);
  cursor: pointer;
  white-space: nowrap;
}

.models-chart-table-wrapper table {
  table-layout: auto;
  display: table !important;
  max-width: 100%;
  height: 100%;
  width: 100%;
}

.models-chart-table-wrapper thead {
  position: sticky;
  top: 0px;
  background-color: var(--colors-neutral-dark);
  box-shadow: var(--shadows-blur-dark-small),
    inset 0 0 0 var(--border-widths-normal)
      var(--colors-semi-transparent-light10);
}

.models-chart-table-wrapper tbody {
  overflow: hidden;
}

.models-chart-table-wrapper table thead tr {
  align-items: flex-end;
}

.models-chart-table-wrapper td {
  width: fit-content !important;
  flex: none;
}

.models-chart-table-wrapper i {
  width: var(--spacing-06);
  height: var(--spacing-06);
}

.sticky-col {
  position: sticky !important;
  left: 0px;
}

.small-col {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-02);
  width: var(--spacing-24);
  cursor: pointer;
}

.medium-col {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-02);
  width: var(--spacing-32);
  cursor: pointer;
}

.large-col {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-02);
  width: var(--spacing-48);
  cursor: pointer;
}

.models-chart-table-wrapper .icon {
  width: var(--spacing-08);
  height: var(--spacing-08);
}

.models-chart-table-wrapper img {
  width: calc(100%);
  height: calc(100%);
  border-radius: 100%;
}

.model-code {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 1px;
  background-color: var(--colors-neutral-dark);
  border-radius: 8px;
  box-shadow: var(--shadows-blur-dark-small),
    inset 0 0 0 var(--border-widths-normal)
      var(--colors-semi-transparent-light10);
  padding: var(--spacing-04);
  box-sizing: border-box;
  overflow-y: scroll;
}

.model-code h3 {
  padding-bottom: var(--spacing-05);
}

.model-name-type-text {
  color: #FFF000;
}

.model-extraction-text {
  color: var(--colors-brand-state-warn-contrast-dark);
}

.model-summarization-text {
  color: var(--colors-brand-state-success-contrast-dark);
}

.model-reasoning-text {
  color: var(--colors-brand-state-error);
}

.model-decision-text {
  color: var(--colors-brand-state-success);
}

.model-understanding-text {
  color: var(--colors-brand-state-warn);
}

.selected-model .footer-button {
  padding: var(--spacing-04);
  position: sticky;
  bottom: 0px;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
}

.compare-modal {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: calc((100% - var(--spacing-06)) / 3) calc((100% - var(--spacing-06)) / 3) calc((100% - var(--spacing-06)) / 3);
  grid-template-rows: 100%;
  gap: var(--spacing-03);
}

.compare-model-col {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: var(--spacing-06);
}