.promo-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: var(--spacing-06);
}

#demo-wrapper {
  width: 100%;
  height: 100%;
  font-size: 16px;
}