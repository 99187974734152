.permission-side-wrapper {
  display: block;
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0px;
  top: 0px;
  z-index: 100;
}

.permission-side-main {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
}

.permission-side-background {
  background-color: var(--colors-semi-transparent-dark50);
}

.permission-side {
  position: absolute;
  right: 0px;
  width: 400px;
  padding: 24px 0px;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: var(--colors-neutral-gray10);
  border-left: 1px solid var(--colors-neutral-gray50);
  z-index: 100;
  font-family: var(--font-families-normal);
}

.permission-side * {
  font-family: var(--font-families-normal);
}

.permission-side-section {
  box-sizing: border-box;
  width: 100%;
  padding: 0px 24px;
}

.permission-sub-header {
  padding: 12px 24px 0px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 24px;
  color: var(--colors-neutral-gray95);
  font-style: italic;
}

.permission-side-header-row {
  display: grid;
  width: 100%;
  grid-template-columns: calc(100% - 40px) 40px;
  grid-template-rows: 36px;
  align-items: center;
}

.permission-side-header-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--colors-neutral-gray95);
  font-size: 24px;
  line-height: 36px;
}

.permission-side-header-icon {
  width: 40px;
  height: 40px;
  padding: 6px 8px;
  box-sizing: border-box;
  color: var(--colors-neutral-gray95);
  display: flex;
  align-items: center;
  justify-content: center;
}

.permission-side-header-icon i {
  width: 24px;
  height: 24px;
  margin: 0px;
  cursor: pointer;
}

.permission-collapse-row {
  width: 100%;
  height: 48px;
  display: grid;
  grid-template-columns: calc(100% - 40px) 40px;
  grid-template-rows: 48px;
  align-items: center;
  border-bottom: 1px solid var(--colors-neutral-gray50);
}

.permission-collapse-row-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--colors-neutral-gray95);
  font-size: 18px;
  line-height: 27px;
}

.permission-collapse-row-icon {
  width: 40px;
  height: 40px;
  padding: 6px 8px;
  box-sizing: border-box;
  color: var(--colors-neutral-gray95);
  display: flex;
  align-items: center;
  justify-content: center;
}

.permission-collapse-row-icon i {
  width: 24px;
  height: 24px;
  margin: 0px;
}

.permission-collapse-body {
  width: 100%;
  height: fit-content;
  overflow: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(72px);
  margin-bottom: 8px;
}

@keyframes openBody {
  0% {
    opacity: 0;
    max-height: 0px;
  }
  100% {
    opacity: 1;
    max-height: 100vh;
  }
}

.permission-collapse-body-open {
  animation: openBody 0.3s forwards;
}

@keyframes closeBody {
  0% {
    opacity: 1;
    max-height: 100vh;
  }
  100% {
    opacity: 0;
    max-height: 0px;
  }
}

.permission-collapse-body-close {
  animation: closeBody 0.3s forwards;
}

.permission-collapse-body-row {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: calc(100% - 40px) 40px;
  min-height: 72px;
  padding-top: var(--spacing-03);
  padding-bottom: var(--spacing-03);
}

.permission-collapse-body-row-text-section {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.permission-collapse-body-row-text-main {
  font-size: 16px;
  line-height: 24px;
  color: var(--colors-neutral-gray95);
}

.permission-collapse-body-row-text-secondary {
  font-size: 14px;
  line-height: 21px;
  color: var(--colors-neutral-gray70);
}

.permission-collapse-body-row-switch {
  display: flex;
  align-items: center;
  justify-content: center;
}

.permission-collapse-body-row-switch i {
  width: 24px;
  height: 24px;
}

.permission-collapse-body-row-switch label {
  margin: 0px;
}

.permission-email {
  color: var(--colors-neutral-gray70);
  font-size: 16px;
  line-height: 25.6px;
  font-family: var(--font-families-stylized);
  padding: 12px 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.permission-active-icon {
  color: var(--colors-brand-bright-green);
}

.disabled-option, .disabled-option * {
  color: var(--colors-neutral-gray50);
  cursor: not-allowed;
}

.permission-select-vkgs-header {
  height: 64px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.permission-select-vkgs-info {
  display: flex;
  height: 40px;
  padding-top: 8px;
  align-items: center;
  justify-content: space-between;
}

.permission-select-vkgs-info-count {
  font-style: italic;
  font-size: 14px;
  line-height: 16px;
}

.permission-select-vkgs-info-options {
  font-size: 14px;
  line-height: 16px;
}

.permission-select-vkgs-info-options .dot-icon {
  color: var(--colors-neutral-gray60)
}

.permission-select-vkgs-info-options .option-text {
  cursor: pointer;
}

.permission-vkg-select-row {
  display: grid;
  padding-top: 8px;
  padding-bottom: 8px;
  grid-template-columns: 24px calc(100% - 40px);
  grid-template-rows: 100%;
  gap: 16px;
  height: 88px;
  align-items: center;
}

.permission-vkg-select-check {
  width: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.permission-vkg-select-check label {
  margin: 0px;
}

.permission-vkg-select-text {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.permission-vkg-select-name {
  font-size: 14px;
  line-height: 21px;
  color: var(--colors-neutral-gray95);
}

.permission-vkg-select-nodes {
  font-size: 12px;
  line-height: 18px;
  color: var(--colors-neutral-gray70);
}

.permission-vkg-lists {
  max-height: 300px;
  --scrollbar-color-track-border: transparent;
}

.permission-role {
  display: grid;
  width: 100%;
  height: 54px;
  grid-template-columns: 52px calc(100% - 52px);
  grid-template-rows: 100%;
  align-items: center;
  font-family: var(--font-families-stylized);
  padding-bottom: 8px;
}

.permission-role * {
  font-family: var(--font-families-stylized);
  margin: 0px;
}

.permission-role-label {
  width: 100%;
  font-size: 12px;
  line-height: 18px;
}

.permission-role-input {
  width: 100%;
}

.permission-action-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-03);
  padding-top: var(--spacing-03);
}

.permission-action-row * {
  font-family: var(--font-families-stylized);
  margin: 0px;
}

.add-users-body {
  width: 100%;
  height: calc(100% - 36px);
  overflow-y: auto;
  font-family: var(--font-families-stylized) !important;
}

.add-users-body * {
  font-family: var(--font-families-stylized) !important;
}

.add-input-wrapper * {
  font-family: var(--font-families-stylized) !important;
}

.add-input-wrapper > * {
  margin: 0px;
  padding: var(--spacing-03) 0px;
}

.add-input-wrapper > div > div div:first-of-type {
  border-radius: 0px !important;
  border-top: none !important;
  border-right: none !important;
  background-color: transparent !important;
}

.add-users-table {
  height: calc(100%);
  width: 100%;
}

.add-users-table tr {
  display: grid;
  grid-template-columns: 2fr 1fr auto;
}

.add-users-table-wrapper {
  height: calc(100% - 88px - 7em);
}

.add-user-bottom-row {
  height: 4em;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: var(--spacing-08);
  gap: var(--spacing-03);
}

.add-user-bottom-row > * {
  margin: 0px;
}
