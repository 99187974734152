.dark .permissionHeader {
  color: var(--colors-brand-bright-green);
}

.light .permissionHeader {
  color: var(--colors-brand-atlantic);
}

.content {
  display: grid;
  gap: var(--spacing-04);
  grid-template-rows: auto auto 1fr;
}

.content h2 {
  font-family: var(--font-families-stylized);
  font-weight: var(--font-weights-regular);
}

.content header,
.header,
.select {
  align-items: center;
  display: grid;
}

.header,
.select {
  grid-auto-flow: column;
  gap: var(--spacing-04);
}

.content header {
  gap: var(--spacing-04);
}

.content label {
  margin: 0;
}

.content button:not(:last-child) {
  margin-bottom: var(--spacing-03);
}

.header {
  grid-template-columns: 1fr auto;
}

.select {
  grid-template-columns: auto 1fr auto;
}

.content a {
  cursor: pointer;
  text-decoration: underline;
}

.content i,
.content .eva {
  height: var(--spacing-04);
  width: var(--spacing-04);
}

.permissionsGrid {
  display: grid;
  gap: var(--spacing-08) var(--spacing-06);
  grid-auto-rows: min-content;
  grid-template-columns: 1fr 1fr;
}

.description {
  margin-bottom: var(--spacing-04);
}
@media screen and (max-width: 1024px) {
  .permissionsGrid {
    gap: var(--spacing-04);
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .permissionsGrid {
    gap: var(--spacing-04);
    grid-template-columns: 1fr;
  }
}

.permissionHeader {
  display: flex;
  gap: var(--spacing-02);
  margin-bottom: var(--spacing-02half);
}
