.vkg-dashboard {
  position: relative;
  width: 100%;
  height: 100%;
}

.vkg-dashboard tr {
  box-sizing: border-box;
  width: calc(100% - 2px);
  margin-left: 1px;
}

.vkg-dashboard thead tr td:last-of-type {
  height: 10px;
}

.vkg-dashboard .tabs {
  position: relative;
  width: 100%;
  height: 100%;
}

.vkg-dashboard .tabs .tab-container {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: var(--spacing-06);
}

.hide-user-table-scroll tbody {
  overflow-y: hidden;
}

.margin-bottom-06 {
  margin-bottom: var(--spacing-06);
}

.permissions-section {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: var(--spacing-06);
}

.vkg-metrics-wrapper {
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: var(--spacing-06);
}

.vkg-metrics {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--spacing-06);
  height: fit-content;
}

.vkg-metrics .card {
  width: calc((100% - var(--spacing-12)) / 3);
  min-width: 200px;
}

.vkg-table-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.vkg-graph-table {
  position: relative;
  height: 100%;
  max-height: 100%;
  margin-bottom: var(--spacing-06);
}

.vkg-graph-table tr {
  grid-template-columns: 5fr 3fr auto;
  display: grid;
}

.link-center {
  display: flex;
  align-items: center;
  height: var(--spacing-06);
  font-size: var(--spacing-03);
  line-height: var(--spacing-03);
}

.vkg-name {
  display: flex;
  align-items: center;
  gap: var(--spacing-02);
}

.vkg-name:hover {
  text-decoration: underline;
}

.vkg-name i {
  margin-bottom: var(--spacing-00half);
}

.vkg-metrics-wrapper thead {
  overflow: hidden;
}

.vkg-table-manage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
}

.vkg-table-manage * {
  margin: 0px;
}

.vkg-manage-table tr {
  grid-template-columns: 2fr 1fr 1fr 1fr auto;
  display: grid;
}

.invisible-btn {
  opacity: 0;
  cursor: default;
}

.manage-graph {
  width: 100%;
  padding: var(--spacing-03) var(--spacing-05);
  box-sizing: border-box;
}

.manage-graph div span {
  width: 100%;
}

.manage-graph-back-row {
  width: fit-content;
  color: var(--colors-brand-bright-green);
  line-height: 16px;
  font-size: 12px;
  padding: var(--spacing-06) 0px;
  box-sizing: border-box;
  cursor: pointer;
  vertical-align: middle;
}

.manage-graph-back-row span {
  vertical-align: inherit;
}

.manage-graph-back-row i {
  width: 16px;
  height: 16px;
}

.manage-graph-title {
  line-height: 29px;
  font-size: 18px;
  font-family: var(--font-families-stylized);
}

.manage-graph-secondary {
  line-height: 21px;
  font-size: 14px;
  font-family: var(--font-families-normal);
}

.manage-graph-select-row {
  padding: var(--spacing-03);
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 52px;
  grid-template-columns: auto 1fr;
  gap: var(--spacing-05);
  align-items: center;
}

.manage-graph-select-row * {
  margin: 0px;
}

.manage-graph-select-row-label {
  line-height: 19px;
  font-size: 12px;
  font-family: var(--font-families-stylized);
}

.manage-graph-select-row-input {
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  max-width: 50%;
}

.manage-graph-table-title-row {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.manage-graph-table-title {
  line-height: 26px;
  font-size: 16px;
  font-family: var(--font-families-stylized);
}

.remove-vkg-access {
  width: 100%;
  text-align: end;
}

.remove-vkg-access i {
  width: 16px;
  height: 16px;
}

.remove-icon:hover {
  color: var(--colors-brand-state-error-contrast-dark)
}

.manage-graph-table-title-row-wrapper {
  width: 25%;
  display: flex;
  align-items: center;
  padding: 4px;
}

.manage-graph-table-title-row-wrapper label {
  margin: 0px;
}