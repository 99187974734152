.custom-modal {
  background-color: var(--colors-neutral-gray20);
  border-radius: 0 0 var(--radii-rounded) var(--radii-rounded);
  box-shadow: var(--shadows-blur-light-medium-light);
  display: grid;
  grid-template-rows: min-content calc(100% - 3rem);
  height: calc(100vh - var(--spacing-24));
  max-height: 44rem;
  max-width: 32rem;
  overflow: hidden;
  position: fixed;
  top: calc(50% - var(--spacing-04));
  transition: calc(1s * var(--durations-long));
  width: calc(100vw - var(--spacing-20));
  z-index: 99;
}

.modal-footer {
  bottom: 0;
  box-sizing: border-box;
  display: grid;
  gap: var(--spacing-03);
  padding: var(--spacing-06);
  position: absolute;
}

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.modal-footer i {
  margin: 0px;
}

.modal-header,
.custom-modal nav {
  height: var(--spacing-12);
}

.modal-header {
  align-items: center;
  background: #FFF000;
  display: flex;
}

.modal-header img {
  height: 100%;
}

.modal-header .header-name {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name-small {
  font-size: var(--font-sizes-normal) !important;
  margin-bottom: calc(-1 * var(--spacing-02));
}

.modal-header span {
  color: var(--colors-neutral-dark);
  font-size: var(--font-sizes-large);
  padding-left: var(--spacing-03half);
  transform: translateY(10%);
}

@media screen and (max-width: 768px) {
  .modal-header {
    height: var(--spacing-10);
  }
  .modal-header span {
    padding-left: var(--spacing-03);
  }
}

.custom-modal nav a {
  text-decoration: none;
  height: 100%;
  flex: 1;
  width: 100%;
  cursor: pointer;
}

.custom-modal nav a button {
  width: 100%;
}

.custom-modal nav button {
  color: var(--colors-neutral-light);
  cursor: pointer;
  flex: 1;
  height: 100%;
  outline: none;
}

.modal-form {
  display: flex;
  flex-direction: column;
  height: calc(100%);
  justify-content: center;
  position: relative;
}

.modal-form > div {
  align-content: center;
  display: grid;
  flex: 1;
  gap: var(--spacing-03);
  margin: 0 var(--spacing-06);
  padding-bottom: var(--spacing-12);
  padding-top: var(--spacing-06);
  min-width: 0;
  overflow: auto;
}

@media screen and (max-height: 750px) {
  .modal-form > div {
    align-content: center;
    gap: var(--spacing-02);
    padding-bottom: var(--spacing-04);
    padding-top: var(--spacing-06);
  }
}

.custom-modal nav {
  display: flex;
}

.custom-modal nav a {
  text-decoration: none;
  height: 100%;
  flex: 1;
  width: 100%;
  cursor: pointer;
}

.copyright-footer {
  bottom: var(--spacing-05);
  font-size: var(--font-sizes-small);
  left: 50%;
  position: fixed;
  text-align: center;
  transform: translateX(-50%);
  width: 90%;
  z-index: 20;
}

.copyright-footer a {
  text-decoration: underline;
}

.cookies-header {
  font-size: var(--font-sizes-small);
  margin-bottom: 0;
  margin-top: var(--spacing-02);
  color: var(--colors-brand-bright-green);
}

.cookies-more-info {
  font-size: var(--font-sizes-small);
  color: var(--colors-brand-light-blue);
  margin: 0;
  margin-top: -1em;
}

.cookies-more-info a {
  text-decoration: underline;
}

.cookies-switch-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cookies-switch-wrapper {
  height: calc(100% - var(--spacing-03));
}

.cookies-switch-state-off {
  padding-left: 12px;
}

.cookies-switch-state-on {
  color: var(--colors-brand-green);
  padding-left: 12px;
}


