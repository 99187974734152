.App {
  background-size: cover;
  height: 100%;
  min-height: 30em;
}

/* this was causing issues with demo page */
/* @media screen and (min-width: 1600px) {
  .App > * {
    font-size: 1.var(--spacing-08);
  }
  .Demo > * {
    font-size: var(--spacing-04);
  }
} */

.center-modal {
  top: 10vh;
  width: 30em !important;
  left: calc(50% - 15em);
  height: 80vh !important;
}

.billing-modal {
  background-color: #333333;
  font-family: var(--font-families-stylized), Fallback, sans-serif !important;
  height: calc(100% - 48px);
  padding: var(--spacing-06);
  position: relative;
}

.billing-modal h2 {
  font-size: 12px !important;
  color: #ffffff;
}

.billing-modal p {
  font-size: 10px !important;
  line-height: 16px !important;
  color: #cccccc;
}

.billing-modal-btns {
  display: flex;
  flex-direction: column-reverse;
  position: absolute;
  bottom: var(--spacing-06);
  width: calc(100% - 48px);
}

.billing-modal-btns button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  line-height: 16px;
  font-size: 10px;
  font-style: var(--font-families-stylized), Fallback, sans-serif !important;
  margin: 12px 0px 0px 0px;
  width: 100%;
}

.billing-modal-btns a {
  width: 100%;
}

.subscribe-btn {
  background-color: #4d4d4d;
  border: 1px solid #999999;
  color: var(--colors-brand-bright-green);
  position: relative;
}

.close-btn {
  background-color: #333333;
  border: 1px solid #999999;
  color: #ff8080;
}

.subscribe-btn img {
  filter: invert(87%) sepia(26%) saturate(956%) hue-rotate(92deg)
    brightness(101%) contrast(102%);
  height: 16px;
  width: 16px;
  position: absolute;
  right: 16px;
}

.consent-modal-content  > a {
  color: #51FFC8;
  text-decoration: underline;
  display: block;
  margin-bottom: .5em !important;
  font-family: 'Anonymous Pro', monospace !important;
}

.consent-body {
  height: calc(100vh - 4em - 60px - 6em);
  overflow-y: auto;
}

.consent-body > p {
  color: white;
  font-family: 'Anonymous Pro', monospace !important;
}

.consent-body > a {
  color: #51FFC8;
  text-decoration: underline;
  display: block;
  margin-bottom: .5em !important;
  font-family: 'Anonymous Pro', monospace !important;
}

.consent-modal-content .buttons {
  position: absolute;
  bottom: 8px;
  left: 8px;
  width: calc(100% - 16px);
  display: flex;
}

.consent-modal-content .buttons button {
  width: calc(100%) !important;
}

.consent-modal-content .buttons button:first-child {
  margin-right: 8px;
}
