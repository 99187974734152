#training-section {
  box-sizing: border-box;
  grid-template-rows:
    auto
    minmax(22rem, 1fr)
    minmax(
      calc(50vh - var(--spacing-32)),
      1fr
    );
  height: 100%;
  padding-bottom: var(--spacing-02);
  padding-left: var(--spacing-02);
  padding-right: var(--spacing-02);
  padding-top: var(--spacing-02);
  overflow: auto;
}

@media screen and (max-width: 768px) {
  #training-section {
    grid-template-rows:
    auto
    minmax(16rem, calc(30vh - var(--spacing-12)))
    minmax(
        20rem,
        1fr
      );
  }
}
.model-training {
  grid-template-rows: auto calc(100% - 2rem);
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.model-training > * {
  padding-bottom: var(--spacing-01);
  padding-left: var(--spacing-03);
  padding-right: var(--spacing-03);
}
.model-training > div {
  box-shadow: 0 calc(-1 * var(--border-widths-normal)) 0 0
    var(--colors-semi-transparent-light20);
  height: calc(100% - var(--spacing-02));
  padding-bottom: 0;
  padding-right: 0;
}

.model-training > div > * {
  padding-top: var(--spacing-02);
}

.model-training > div i {
  min-width: var(--spacing-03);
}

.model-training > div div {
  height: 100%;
}

.training-file-input p {
  margin-top: var(--spacing-01);
}

#training form,
#training label {
  width: 100%;
}

#training h4 {
  font-weight: var(--font-weights-regular);
}
#training label {
  cursor: pointer;
}

#training-input-container .card-title {
  height: 0.5em;
}

.model-info p {
  font-size: 1.3125em;
  color: white;
}

.select-group {
  display: flex;
}

.model-select {
  /* background: #3A3A3A; */
  background: rgb(27, 27, 27);
  border-top: none;
  border-right: none;
  border-color: #9b9b9b;
  color: white;
  padding: 10px;
  width: 100%;
  font-size: 1.3125em;
  border-radius: 0;
  padding-right: calc(1.3125em + 20px);
}

.model-select:focus {
  outline: 0;
}

.model-select:hover {
  cursor: pointer;
}

.model-type-arrow {
  margin-left: calc(-1.3125em - 20px) !important;
  height: calc(1.3125em + 20px); /* the text plus top/bottom padding */
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(299deg)
    brightness(106%) contrast(101%);
  pointer-events: none;
}

.model-training-instructions i {
  width: auto;
}
