.auth-page {
  inset: 0;
  min-height: 48rem;
  position: fixed;
  z-index: 30;
}

.sign-in-form {
  flex: 1;
  display: flex;
  overflow: auto;
}

.mfa-form {
  padding: var(--spacing-06) var(--spacing-06) 0;
  background: var(--colors-neutral-gray20);
  position: relative;
}

.profile-mfa-form {
  margin-top: 1em;
}

.mfa-form footer {
  box-sizing: border-box;
  display: grid;
  gap: var(--spacing-03);
  position: absolute;
  bottom: var(--spacing-06);
  width: calc(100% - var(--spacing-12));
}

.slideIn {
  animation: slideInAuth calc(1s * var(--durations-medium)) forwards;
}

.slideOut {
  animation: slideOutAuth calc(1s * var(--durations-medium)) forwards;
}

.sign-in-form {
  box-sizing: border-box;
}

#sign-in-inputs {
  margin-bottom: calc(var(--spacing-24) + var(--spacing-05));
}

.signup-pwd-header, .password-checklist {
  font-size: var(--font-sizes-small);
}

@keyframes slideInAuth {
  from {
    transform: translate(100%, -50%);
    right: -100%;
    opacity: 0;
  }

  to {
    transform: translate(-50%, -50%);
    right: 50%;
    opacity: 1;
  }
}

@keyframes slideOutAuth {
  from {
    transform: translate(-50%, -50%);
    right: 50%;
    opacity: 1;
  }

  to {
    transform: translate(100%, -50%);
    left: 100%;
    opacity: 0;
  }
}
