.model-limit-usage-card {
  /* background-color: var(--colors-semi-transparent-light5) !important; */
  border: 1px solid var(--colors-semi-transparent-light10) !important;
  padding: 10px 12px !important;
  font-family: var(--font-families-normal) !important;
  margin-top: 8px;
}

.model-limit-usage-card-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.model-limit-usage-card-wrapper > .individual-limit-wrapper {
  min-width: calc(50% - 4px);
  max-width: calc(50% - 4px);
}

.individual-limit-wrapper {
  width: max-content;
  height: fit-content;
  box-sizing: border-box;
  flex: 1 0 auto;
}

@media screen and (max-width: 1260px) {
  .model-limit-usage-card-wrapper > .individual-limit-wrapper {
    min-width: 500px;
    max-width: calc(50% - 4px);
  }
}

@media screen and (max-width: 1150px) {
  .model-limit-usage-card-wrapper > .individual-limit-wrapper {
    min-width: 500px;
    max-width: calc(100%);
  }
}

.model-limit-usage-card h3 {
  font-family: var(--font-families-normal) !important;
  font-size: 18px;
  line-height: 24px;
  color: var(--colors-neutral-gray95);
}

.limit-row {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--colors-semi-transparent-light5);
}

.limit-row-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: var(--colors-neutral-gray98);
  font-family: var(--font-families-normal) !important;
  padding-bottom: 4px;
}

.limit-row:hover {
  background-color: var(--colors-semi-transparent-light5) !important;
  cursor: pointer;
  border: 1px solid var(--colors-semi-transparent-light50);
}

.limit-row-selected {
  border: 1px solid var(--colors-neutral-gray98) !important;
}

.model-limit-usage-card .limit-row:first-of-type {
  margin-top: 4px !important;
}

.model-limit-usage-card .limit-row {
  margin-top: 8px;
}

.limit-row .limit-header-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 16px;
  width: calc(100%);
  gap: 12px;
}

.limit-row .limit-header-row *:last-child {
  margin-left: auto;
}

.limit-row .limit-header-row .limit-key {
  width: fit-content;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--colors-neutral-gray98);
  font-family: var(--font-families-normal) !important;
  /* min-width: 200px; */
  white-space: nowrap;
}

.limit-row .limit-header-row .limit-value {
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--colors-neutral-gray70);
  font-family: var(--font-families-normal) !important;
}

.limit-row .limit-progress-bar {
  width: 100%;
  height: 8px;
  position: relative;
  margin-top: 8px;
}

.limit-progress-bar-base {
  /* background-color: var(--colors-semi-transparent-light5) !important; */
  border: 1px solid var(--colors-semi-transparent-light20) !important;
  border-radius: 4px;
  height: 8px;
  box-sizing: border-box;
}

.limit-progress-bar-progress {
  position: absolute;
  border-radius: 4px;
  top: 0px;
  left: 0px;
  height: 8px;
}

.progress-not-updated {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--colors-neutral-gray70);
  font-family: var(--font-families-normal) !important;
}

.limit-info {
  width: calc(100%);
  box-sizing: border-box;
  height: fit-content;
  margin-top: 8px;
  padding: 8px;
  overflow: hidden;
}

.limit-info-closed {
  display: none;
  max-height: 0px;
  animation-name: infoClose;
  animation-duration: .2s;
}

.limit-info-open {
  display: block;
  animation-name: infoOpen;
  animation-duration: .2s;
}

@keyframes infoOpen {
  0% {display: none; margin-top: 0px; padding: 0px 8px;}
  1% {display: block; max-height: 0px; opacity: 0.01; margin-top: 0px; padding: 0px 8px;}
  100% {display: block; max-height: 400px; opacity: 1; margin-top: 8px; padding: 8px 8px;}
}

@keyframes infoClose {
  0% {display: block; max-height: 300px; opacity: 1; margin-top: 8px; padding: 8px 8px;}
  99% {display: block; max-height: 0px; opacity: 0.01; margin-top: 0px; padding: 0px 8px;}
  100% {display: none; margin-top: 0px; padding: 0px 8px;}
}

.limit-info-side {
  width: 100%;
  height: fit-content;
}

.limit-info-kvp {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid var(--colors-semi-transparent-light10);
  box-sizing: border-box;
  margin-bottom: 8px;
}

.limit-info-key, .enforcement-state {
  font-size: 16px;
  line-height: 22px;
  color: var(--colors-neutral-gray98);
  font-family: var(--font-families-normal) !important;
  white-space: nowrap;
}

.limit-info-key {
  position: relative;
}

.limit-info-value {
  font-size: 12px;
  line-height: 21px;
  color: var(--colors-neutral-gray70);
  font-family: var(--font-families-normal) !important;
}

.limit-info-loader {
  width: 100%;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.limit-info-loader svg {
  position: relative;
  height: 12px;
  width: 12px;
}

.limit-calculation {
  font-size: 16px;
  line-height: 21px;
  color: var(--colors-neutral-gray98);
  font-family: var(--font-families-normal) !important;
  padding: 0px 4px;
  text-align: center;
}

.calculation-side {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 20px 1fr;
}

.limit-refresh, .limit-refresh-date {
  display: flex;
  gap: 4px;
  height: 16px;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  font-family: var(--font-families-normal) !important;
  color: var(--colors-neutral-gray70);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.limit-refresh span {
  font-size: 12px;
  line-height: 18px;
  font-family: var(--font-families-normal) !important;
}

.limit-refresh i {
  margin: 0px;
}

.limit-refresh .limit-info-loader {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.enforcement-explanation {
  display: none;
  height: 0px;
  width: 0px;
}

.enforcement-state {
  /* text-decoration: underline; */
  cursor: help;
}

.enforcement-state i {
  height: 16px;
  width: 16px;
  margin-right: 0px;
  margin-left: 4px;
  vertical-align: middle;
  padding-bottom: 2px;
}

.enforcement-state:hover .enforcement-explanation {
  display: block;
}

.enforcement-explanation > * {
  position: absolute;
  height: 50px;
  width: 100px;
  top: 0px;
  left: 150px;
  z-index: 10;
  white-space: wrap;
  max-width: 200px;
}

.enforcement-explanation p {
  text-decoration: none !important;
}

.enforcement-explanation button {
  display: none;
}

.enforcement-explanation > * > *:nth-child(2) {
  min-height: 50px !important;
}
