.css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
}

.css-7pg0cj-a11yText {
    z-index: 9999;
    border: 0px;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    padding: 0px;
    white-space: nowrap;
}

.css-13cymwt-control {
    -webkit-box-align: center;
    align-items: center;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    position: relative;
    transition: all 100ms ease 0s;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    outline: 0px !important;
}

.css-1jqq78o-placeholder {
    grid-area: 1 / 1 / 2 / 3;
    color: rgb(128, 128, 128);
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
}

.css-19bb58m {
    visibility: visible;
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: 1 / 1 / 2 / 3;
    grid-template-columns: 0px min-content;
    margin: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
    color: rgb(51, 51, 51);
    box-sizing: border-box;
}

.css-1wy0on6 {
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
}

.css-1u9des2-indicatorSeparator {
    align-self: stretch;
    width: 1px;
    background-color: rgb(204, 204, 204);
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box;
}

.css-1xc3v61-indicatorContainer {
    display: flex;
    transition: color 150ms ease 0s;
    color: rgb(204, 204, 204);
    padding: 8px;
    box-sizing: border-box;
}

.css-hlgwow {
    -webkit-box-align: center;
    align-items: center;
    display: grid;
    flex: 1 1 0%;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    padding: 2px 8px;
    box-sizing: border-box;
}

.css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
}

.css-1f43avz-a11yText-A11yText {
    z-index: 9999;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
}

.css-13cymwt-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
}

.css-13cymwt-control:hover {
    border-color: hsl(0, 0%, 70%);
}

.css-1fdsijx-ValueContainer {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: grid;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;
    padding: 2px 8px;
    box-sizing: border-box;
}

.css-1jqq78o-placeholder {
    grid-area: 1/1/2/3;
    color: hsl(0, 0%, 50%);
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
}

.css-qbdosj-Input {
    visibility: visible;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: 1/1/2/3;
    grid-template-columns: 0 min-content;
    margin: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
    color: hsl(0, 0%, 20%);
    box-sizing: border-box;
}

.css-qbdosj-Input:after {
    content: attr(data-value) " ";
    visibility: hidden;
    white-space: pre;
    grid-area: 1/2;
    font: inherit;
    min-width: 2px;
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
}

.css-1hb7zxy-IndicatorsContainer {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    box-sizing: border-box;
}

.css-1u9des2-indicatorSeparator {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    width: 1px;
    background-color: hsl(0, 0%, 80%);
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box;
}

.css-1xc3v61-indicatorContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: color 150ms;
    transition: color 150ms;
    color: hsl(0, 0%, 80%);
    padding: 8px;
    box-sizing: border-box;
}

.css-1xc3v61-indicatorContainer:hover {
    color: hsl(0, 0%, 60%);
}

.css-1dimb5e-singleValue {
    grid-area: 1 / 1 / 2 / 3;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgb(51, 51, 51);
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
}

.css-t3ipsp-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: #2684FF;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 0 1px #2684FF;
    box-sizing: border-box;
}

.css-t3ipsp-control:hover {
    border-color: #2684FF;
}

.css-15lsz6c-indicatorContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: color 150ms;
    transition: color 150ms;
    color: hsl(0, 0%, 40%);
    padding: 8px;
    box-sizing: border-box;
}

.css-15lsz6c-indicatorContainer:hover {
    color: hsl(0, 0%, 20%);
}

.css-1nmdiq5-menu {
    top: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box;
}

.css-1n6sfyn-MenuList {
    max-height: 300px;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 4px;
    padding-top: 4px;
    box-sizing: border-box;
}

.css-d7l1ni-option {
    cursor: default;
    display: block;
    font-size: inherit;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: #DEEBFF;
    color: inherit;
    padding: 8px 12px;
    box-sizing: border-box;
    word-break: break-word;
}

.css-d7l1ni-option:active {
    background-color: #B2D4FF;
    word-break: break-word;
}

.css-10wo9uf-option {
    cursor: default;
    display: block;
    font-size: inherit;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: transparent;
    color: inherit;
    padding: 8px 12px;
    box-sizing: border-box;
    word-break: break-word;
}

.css-10wo9uf-option:active {
    background-color: #B2D4FF;
    word-break: break-word;
}

.css-tr4s17-option {
    padding: 8px 12px;
    box-sizing: border-box;
    word-break: break-word;
}

.css-qr46ko {
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: none !important;
}