.template {
  display: grid;
  grid-template-columns: minmax(var(--spacing-12), auto) repeat(11, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: "a b b b b b b b b b b b";
  height: 100%;
  color: white;
  transition: all calc(1s * var(--durations-long));
}

.nav-expanded .template {
  grid-template-columns: minmax(var(--spacing-56), auto) repeat(11, 1fr);
}

.template > main {
  height: 100%;
  grid-area: b;
  overflow: hidden;
  min-width: 0;
}

.template .dynamic-content {
  background: var(--colors-neutral-gray15);
  box-sizing: border-box;
  height: calc(100vh - var(--spacing-12));
  overflow: hidden;
  padding-left: var(--spacing-01);
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: bold;
}

/* .template aside {
  grid-area: c;
} */

#logo-header {
  height: var(--spacing-12);
  width: auto;
  background: rgb(26, 26, 26);
  position: fixed;
  z-index: 11;
}

#logo-header > img:first-child {
  height: var(--spacing-12);
  width: auto;
  position: fixed;
  z-index: 2;
}

#logo-header > div {
  height: var(--spacing-12);
  width: var(--spacing-12);
  display: flex;
  justify-content: center;
  align-items: center;
}

#logo-header > div > img {
  height: 1.5em;
  width: 1.5em;
  margin: 0 !important;
  cursor: pointer;
}

.template nav .links {
  margin-top: 18px;
}

.no-top-margin {
  margin-top: 0px !important;
  height: 100% !important;
}

@media screen and (max-width: 768px) {
  .template {
    grid-template-areas: "a b b b b b b b b b b b";
    grid-template-rows: 1fr;
    grid-template-columns: minmax(var(--spacing-12), auto) repeat(11, 1fr);
  }

  .signout-link {
    bottom: calc(var(--spacing-12) + 8px) !important;
  }
}
